import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns'
import { Table, Stack, Dropdown, FormControl, InputGroup, Button } from 'react-bootstrap';
import { sort } from 'fast-sort';
import { MdDownload, MdOutlineSearch, MdBackspace } from "react-icons/md";
import ConditionalRender from '../ConditionalRender'
import ReactDOMServer from 'react-dom/server'
import XLSX from 'xlsx';

import DataTablePaginator from './DataTablePaginator';
import useWindowDimensions from './useWindowDimensionsHook';
import GetExcelFile from './GetExcelFile';

const dataFormatters = {
    formatAsDate: (data) => {
        
        let cellData = data;
        const date = new Date(cellData);
        if (date.toString() !== "Invalid Date") {
            if (date > new Date("01/01/2000")) {
                cellData = format(date, 'MM/dd/yyyy');
            } else {
                cellData = '';
            }
        }
        return cellData;
    },

    formatAsDateTime: (data) => {

        let cellData = data;
        const date = new Date(cellData);
        if (date.toString() !== "Invalid Date") {
            if (date > new Date("01/01/2000")) {
                cellData = format(date, 'MM/dd/yyyy HH:mm  a..aaa');
            } else {
                cellData = '';
            }
        }
        return cellData;
    },
    formatAsHtml: (str) => {
        return <span dangerouslySetInnerHTML={{ __html: str } } />
    },
    formatAsCurrency: (num) => {
        //let tmpVal = '';
        //if (num) {
        //    tmpVal = '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        //}
        return num;
    },
    formatAsBoolean: (num) => {
        let tmpVal = '';
        if (num) {
            tmpVal = Boolean(Number(num)) == true ? 'Yes' : 'No';
        }
        return tmpVal;
    },
    formatCrlfAsHtml: (val) => {
        return val; // val.replaceAll(/\r?\n|\r/gi, '<br/>')
    }

}

const DataTable = (props) => {
    let pageSize = 10;
    const tableHeaderRef = useRef(null);
    const reportHeaderRef = useRef(null);
    const tableBodyRef = useRef(null);
    const tableRef = useRef(null);

    const [columnDefs, setColumnDefs] = useState([]);
    const [headerStyles, setHeaderStyles] = useState([]);
    const [defaultSortBy, setDefaultSortBy] = useState([]);
    const [baseData, setBaseData] = useState([]);
    const [workingData, setWorkingData] = useState([]);
    const [pagedData, setPagedData] = useState([]);
    const [reportTitle, setReportTitle] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useState("");

    const [reportHeaderHeight, setReportHeaderHeight] = useState(0);
    const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
    const [tableHeight, setTableHeight] = useState(0);
    const windowSize = useWindowDimensions();

    useEffect(() => {
        setColumnDefs(props.columns);
        setHeaderStyles(props.headerStyles);
        setDefaultSortBy(props.defaultSortBy);

        setBaseData(prepData(props.data, props.defaultSortBy));
        setReportTitle(props.reportTitle);
    }, [props])

    useEffect(() => {
        setWorkingData([...baseData]);
    }, [baseData])

    useEffect(() => {
        const tableHeaderHeight = tableHeaderRef.current.getBoundingClientRect().height;
    }, [tableHeaderRef])

    useEffect(() => {
        const localReportHeaderHeight = reportHeaderRef.current.getBoundingClientRect().height;
        setReportHeaderHeight(localReportHeaderHeight);
    }, [reportHeaderRef])

    useEffect(() => {
        changeTableHeight()
    }, [windowSize])

    useEffect(() => {
        changeTableHeight()
    }, [reportHeaderHeight])

    useEffect(() => {
        // change paged data
        const current = (currentPage - 1) * pageSize;
        const end = current + pageSize;
        var result = workingData.slice(current, end);
        setPagedData(result);
    }, [currentPage, workingData])

    useEffect(() => {
        setCurrentPage(1);
    }, [workingData])

    useEffect(() => {
        if (searchString === '') {
            searchData();
        }
    }, [searchString])

    const changeTableHeight = () => {
        var tableHeight = windowSize.height - reportHeaderHeight;
        setTableHeight(tableHeight)
    }

    const prepData = (data, sortColumns) => {
        let sortBy = [];
        if (sortColumns) {
            sortBy = sortColumns.map(s => { return u => u[s]})
        }
        const sorted = sort(data).asc(sortBy);

        return sorted;
    }

    const searchData = () => {
        let result = [];
        if (searchString === '') {
            result = [...baseData];
        } else {
            let searchStringLower = searchString.toLowerCase();
            //let props = Object.keys(baseData[0])
            //result = baseData.filter(row => {
            //    props.forEach(prop => {
            //        let propValue = row[prop];
            //        if (propValue?.toString().toLowerCase().includes(searchStringLower)) {
            //            return true;
            //        }
            //    })
            //    return false;
            //})
            result = filterByValue(baseData, searchStringLower)
        }

        //let result = baseData.filter(obj => {
        //    for (let prop in props) {
        //        let propValue = obj[props[prop]];
        //        if (propValue?.toString().includes(searchString)) {
        //            return true;
        //        }
        //    }
        //    return false;
        //});
        setWorkingData(result);        
    }

    const filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(k => o[k]?.toString().toLowerCase().includes(string)));
    }

    const downloadExcel = () => {
        //var html = tableRef.current.innerHTML;
                    //        <TableHeader columnDefs={columnDefs} reference={tableHeaderRef} />
                    //<TableBody columnDefs={columnDefs} data={pagedData} reference={tableBodyRef} />
        //var params =
        //var html2 = TableBody({ "columnDefs": columnDefs, "data": workingData, "reference": tableBodyRef });
        //var html = ReactDOMServer.renderToString(
        //    <Table striped bordered style={{ fontSize: "0.8rem" }}>
        //        <TableBody columnDefs={columnDefs} data={pagedData} reference={tableBodyRef} />
        //    </Table>);
        //var htmlBody = htmlHead + ReactDOMServer.renderToString(<TableBody columnDefs={columnDefs} data={pagedData} reference={tableBodyRef} />);
        //GetExcelFile(html, reportTitle)
        //const wb = XLSX.utils.table_to_book(html);

        ///* Export to file (start a download) */
        //XLSX.writeFile(wb, `${reportTitle}.xlsx`);

        let data = []
            //Object.keys().forEach(colDef => {
        // workingData
        const colRowData = [];
        columnDefs.forEach(col => {
            colRowData.push(col.text);
        })
        data.push(colRowData)

        workingData.forEach(row => {
            const rowData = [];
            columnDefs.forEach(colDef => {
                let val = row[colDef.dataField];
                if (colDef.formatter) {
                    val = dataFormatters[colDef.formatter](val);
                }
                rowData.push(val);
            })
            data.push(rowData);
        })

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        var subReportTitle = reportTitle.replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        subReportTitle = subReportTitle.substring(0, 30);
        XLSX.utils.book_append_sheet(workbook, worksheet, subReportTitle);
        XLSX.writeFile(workbook, `${reportTitle}.xlsx`);

        return data;

        //const tableToExcel = new TableToExcel();
        //tableToExcel.render("tableId", html);
    }

    return (
        <>
            {
            //    height - tableHeaderHeight - reportHeaderHeight
            }
            
            <Stack ref={reportHeaderRef} direction="horizontal" gap={1} style={{ margin: "0", padding: "0.5rem", backgroundColor: "white" } }>
                <div style={{ fontWeight: "bold" }}>{reportTitle}</div>
                <span className="ms-auto"></span>
                <InputGroup style={{ width: "300px" }}>
                    <FormControl
                        id="search"
                        size="sm"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value.trim())}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                searchData();
                            }
                        }}
                        placeholder="Search Text" />
                    <Button size="sm" className={searchString === '' ? 'd-none' : ''} style={{ marginLeft: "-40px", zIndex: "100", border: "0", backgroundColor: "transparent" }}>
                        <MdBackspace size="1.5em" style={{ color: "red" }} onClick={() => setSearchString('')} />
                    </Button>
                    <Button size="sm" onClick={() => searchData()} variant="outline-dark"><MdOutlineSearch size="1.5em"/></Button>
                </InputGroup>
                <Dropdown id="dropdown-basic-button" title="Dropdown button" >
                    <Dropdown.Toggle variant="outline-dark" size="sm" >
                        <MdDownload size="1.5em" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => downloadExcel() }>Excel</Dropdown.Item>
                        <Dropdown.Item>CSV</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <DataTablePaginator
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={workingData.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                    />
            </Stack>
            <div className="" style={{ overflow: "auto", maxHeight: tableHeight } }>
                <Table striped bordered style={{ fontSize: "0.8rem", marginBottom: 0 }}>
                    <TableHeader columnDefs={columnDefs} headerStyles={headerStyles} reference={tableHeaderRef} />
                    <TableBody columnDefs={columnDefs} data={pagedData} reference={tableBodyRef} />
                </Table>
            </div>
            {
                //JSON.stringify(columnDefs, null, 2)
            }
            {
                //JSON.stringify(baseData, null, 2)
            }
        </>
    );
}

const TableHeader = (props) => {
    return (
        <>
            <thead ref={props.reference} style={{ position: "sticky", top: "0", padding: 0 } }>
                <tr style={{ ...props.headerStyles }}>
                    {
                        props.columnDefs.map((col, idx) => { return (<TableHeaderColumn key={idx} columnDef={col} />) })
                    }
                </tr>
            </thead>
        </>
    );
}
const TableHeaderColumn = (props) => {

    return (
        <th key={props.key} style={{ whiteSpace: "nowrap", position: "sticky", top: "0" }}>{props.columnDef.text}</th>
    );
}

const TableBody = (props) => {
    {
        //style = {{ maxHeight: `${props.bodyHeight}px`, overflowY: "scroll", display: "block" }
    }
    return (
        <tbody ref={props.reference}> 
            {
                props.data?.map((dataRow, idx) => {
                    return (<TableDataRow key={idx} dataRow={dataRow} columnDefs={props.columnDefs} />);
                })
            }
        </tbody>
    );
}

const TableDataRow = (props) => {
    return (
        <tr key={props.key}>
            {
                props.columnDefs?.map((col, idx) => {
                    return (<TableDataRowColumn key={idx} data={props.dataRow[col.dataField]} colDef={col} />) })
            }
        </tr>
    );
}

const TableDataRowColumn = (props) => {
    let data = props.data;

    let styles = props.colDef.styles??{};
    if (props.colDef.nowrap) {
        styles.whiteSpace = "nowrap"
    }

    if (props.colDef.formatter) {
        data = dataFormatters[props.colDef.formatter](data);
    }
    return (
        <td key={props.key} style={ styles }>{data}</td>
    );
}





export default DataTable;