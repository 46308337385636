import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

    const handleResize = () => {
        setWindowDimensions({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        //function handleResize() {
        //    setWindowDimensions(getWindowDimensions());
        //}
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default useWindowDimensions;