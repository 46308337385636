import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const Home = (props) => {

    //let [activity, setActivity] = useState([]);

    //useEffect(() => {
    //    getData();
    //}, [])

    //const getData = async () => {
    //    const response = await fetch(`/api/webhooks/mailchimp/activity`, { timeout: 60000 });
    //    const data = await response.json();
    //    setActivity(data);
    //}

    return (
        <div>
            Calpelra
            {/*{*/}
            {/*    activity.map((a, idx) => {*/}
            {/*        return (*/}
            {/*            <div>{JSON.stringify(a, null, 0)}</div>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
            {/*<Link to="/reportviewer?report=GenericMemberRegistration&p1name=event_id&p1value=406&rheader=2022-2023%20CALPELRA%20Membership">2022-2023 CALPELRA Membership</Link><br />*/}
            {/*<Link to="/reportviewer?report=GenericPaidMemberRegistration&p1name=event_id&p1value=406&rheader=2022-2023%20CALPELRA%20Membership">2022-2023 CALPELRA Membership Paid</Link><br />*/}
            {/*<Link to="/reportviewer?report=GenericUnpaidMemberRegistration&p1name=event_id&p1value=406&rheader=2022-2023%20CALPELRA%20Membership">2022-2023 CALPELRA Membership Unpaid</Link><br />*/}
            {/*<Link to="/reportviewer?report=GenericAllParticipantsCALIndicator&rheader=2021%20All%20Participants%20CAL%20Indicator">All Participants with CAL Indicator</Link>*/}
            {/*<br /><br />*/}
            {/*<Link to="/reportviewer?report=genericacademy&p1name=AcademyID&p1value=404&rheader=Core%20Academy%20Online:%20Managing%20Collective%20Bargaining%20Agreements,%20August%2010-11,%202022">Academy All</Link><br />*/}
            {/*<Link to="/reportviewer?report=genericacademypaid&p1name=AcademyID&p1value=402&rheader=Core%20Academy%20Online:%20Negotiations%20Simulation%20And%20Strategy,%20%20December%207-8,%202022">Academy Paid</Link><br />*/}
            {/*<Link to="/reportviewer?report=genericacademyunnpaid&p1name=AcademyID&p1value=395&rheader=CALPELRALearn%20Online:%20Disability%20Accommodation%20And%20The%20Interactive%20Process,%20Sept.%2027-28,%202021">Academy Unpaid</Link><br/>*/}
            {/*<Link to="/reportviewer?report=AllParticipantsCLRMACA&rheader=All%20Participants%20with%20CLRM%20or%20ACA">All Participants with CLRM or ACA</Link>*/}
        </div>
    );
}

export default Home;