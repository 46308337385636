import React, { useState, useEffect } from 'react';
import { Redirect, useSearchParams } from "react-router-dom";

import { useImmer } from 'use-immer';
import { format } from 'date-fns'
import { Container, Col, Row } from 'react-bootstrap';
import LoadingOverlayWrapper from '../../controls/loaderoverlay/loaderoverlay';
import { BarChart, Label, LabelList, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


//const datar = [
//    {
//        name: 'Complimentary',
//        uv: 400,
//        pv: 240,
//        amt: 240,
//    },
//    {
//        name: 'Friend',
//        uv: 300,
//        pv: 139,
//        amt: 221,
//    },
//    {
//        name: 'Member',
//        uv: 200,
//        pv: 780,
//        amt: 220,
//    },
//    {
//        name: 'Non-member',
//        uv: 278,
//        pv: 390,
//        amt: 200,
//    },
//];

const ChartViewer = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();

    const [reportTitle, setReportTitle] = useState('');
    const [xAxisField, setXAxisField] = useState('');
    const [xAxisTitle, setXAxisTitle] = useState('');
    const [yAxisTitle, setYAxisTitle] = useState('');
    const [seriesFields, setSeriesFields] = useState([]);
    const [seriesTitles, setSeriesTitles] = useState([]);
    const [seriesColors, setSeriesColors] = useState([]);
    const [data, setData] = useState([]);
    const [queryParams, setQueryParams] = useImmer({ reportTitle: '', reportVariant: '' })
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [maxVal, setMaxVal] = useState(0);

    const [queryString, setQueryString] = useState('');

    useEffect(() => {
        setQueryParams(draft => {
            draft.reportTitle = searchParams.get('rheader');
            draft.reportVariant = searchParams.get('report');
            if (searchParams.get('p1value') != null) {
                draft.paramValue = searchParams.get('p1value');
                draft.paramName = searchParams.get('p1name');
            }
        })
    }, [])

    useEffect(() => {
        if (queryParams.reportVariant != '') {
            let result = '?' + new URLSearchParams(queryParams).toString()
            setQueryString(result);
        }
    }, [queryParams.reportVariant])

    useEffect(() => {
        fetchData()
    }, [queryString])

    const fetchData = async () => {
        if (queryString) {
            const response = await fetch(`/api/charts/generic${queryString}`, { timeout: 60000 });
            const data = await response.json();
            if (!data.success) {
                setError("Unable to load data.")
            }
            else {
                if (data.payload?.results.length > 0) {
                    var results = data.payload.results;
                    setData(results);

                    var seriesFields = data.payload.seriesFields;
                    setSeriesFields(seriesFields);
                    var seriesTitles = data.payload.seriesTitles;
                    setSeriesTitles(seriesTitles);
                    var seriesColors = data.payload.seriesFieldColors;
                    setSeriesColors(seriesColors);

                    let xAxisField = data.payload.xAxisField;
                    let xAxisTitle = data.payload.xAxisTitle;
                    let yAxisTitle = data.payload.yAxisTitle;
                    setXAxisField(xAxisField);
                    setXAxisTitle(xAxisTitle);
                    setYAxisTitle(yAxisTitle);

                    let allVals = [];
                    seriesFields.forEach(field => {
                        allVals = allVals.concat(results.map((o) => o[field]));
                    })

                    let max = Math.max(...allVals);
                    let ceil = (Math.ceil(max / 100) + 1) * 100;
                    setMaxVal(ceil);

                }
                setReportTitle(queryParams.reportTitle);
            }

            setIsLoading(false);
        }

        //setIsLoading(false);
    }

    const getTextForSpinner = () => {
        return (
            <><b>Fetching Chart</b><br />{queryParams.reportTitle}</>
        )
    }

    return (
        <>
            {
                //JSON.stringify(searchParams, null, 2)


            }

            <LoadingOverlayWrapper
                active={isLoading}
                spinner
                text={getTextForSpinner()} hideChildrenIfLoading={true}
            >
                {error ? <div className="text-danger bold">{error}</div> : null}
                <table style={{ backgroundImage: 'linear-gradient(#c9dcf8, #e3edfc)', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '10px', border: '3px solid #7393cb', borderCollapse: 'separate', borderSpacing: '0' }}>
                    <tbody>
                        <tr>
                            <td
                                style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: '10px', paddingBottom: '10px', textAlign: "center" }}>
                                <h5>{reportTitle}</h5>
                            </td>
                        </tr>
                        <tr><td>
                            <BarChart
                                width={760}
                                height={400}
                                data={data}
                                margin={{
                                    top: 0,
                                    right: 30,
                                    left: 30,
                                    bottom: 30,
                                }}


                            >
                                <CartesianGrid strokeDasharray="3 3" fill="#E6EEFC" />
                                <XAxis border="none"
                                    dataKey={xAxisField}
                                    tick={{ fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }}
                                    label={{ value: xAxisTitle, position: 'insideBottom', offset: '-10', fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }} />
                                <YAxis yAxisId="left"
                                    domain={[0, maxVal]}
                                    tick={{ fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }}
                                    label={{ value: 'Totals', angle: -90, position: 'insideLeft', textAnchor: 'middle', fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }} />

                                <Legend
                                    verticalAlign="bottom"
                                    align="left"
                                    iconSize="20"
                                    iconType='rect'
                                    formatter={(value, entry, index) => <span style={{ color: '#000', fontWeight: 'bold' }}>{value}</span>}
                                    style={{ fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }} />
                                {
                                    seriesFields.map((field, idx) => {
                                        return (
                                            <Bar yAxisId="left" dataKey={field} fill={seriesColors[idx]} >
                                                <LabelList dataKey={seriesTitles[idx]} position="top" style={{ fontSize: '80%', fontColor: '#000', fontWeight: 'bold' }} />
                                            </Bar>
                                        )
                                    })
                                }

                            </BarChart>
                        </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ textAlign: 'center', fontSize: '12px', paddingTop: '10px' } }>{format(new Date(), 'M/d/yyyy h:mm  a')}</div>
            </LoadingOverlayWrapper>
            {
                //    JSON.stringify(data, null, 2)
            }
        </>
    );

}


export default ChartViewer;