import React, { Component } from 'react';
import { Route, Routes, Redirect } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Home from './components/Home';
import { Layout } from './components/Layout';
import ChartViewer from './components/Reporting/ChartViewer';
import ReportViewer from './components/Reporting/ReportViewer';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/reportviewer" element={<ReportViewer />} />
                    <Route path="/chartviewer" element={<ChartViewer/>} />
                    {/*{AppRoutes.map((route, index) => {*/}
                    {/*    const { element, ...rest } = route;*/}
                    {/*    return <Route key={index} {...rest} element={element} />;*/}
                    {/*})}*/}
                </Routes>
            </>
        );
    }
}
