
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import React, { useState, useEffect } from 'react';
import { Redirect, useSearchParams } from "react-router-dom";
import { useImmer } from 'use-immer';
import { Container, Col, Row } from 'react-bootstrap';
//import { memberRegistration } from './MemberRegistration';
import DataTable from '../../controls/datatable/DataTable';
import LoadingOverlayWrapper from '../../controls/loaderoverlay/loaderoverlay';


const ReportViewer = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    
    const [reportTitle, setReportTitle] = useState('');
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultSortBy, setDefaultSortBy] = useState([]);
    const [headerStyles, setHeaderStyles] = useState([]);
    const [data, setData] = useState([]);
    const [queryParams, setQueryParams] = useImmer({ reportTitle: '', reportVariant: '' })
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [queryString, setQueryString] = useState('');

    useEffect(() => {
        setQueryParams(draft => {
            draft.reportTitle = searchParams.get('rheader');
            draft.reportVariant = searchParams.get('report');
            if (searchParams.get('p1value') != null) {
                draft.paramValue = searchParams.get('p1value');
                draft.paramName = searchParams.get('p1name');
            }
        })
    }, [])

    useEffect(() => {
        if (queryParams.reportVariant != '') {
            let result = '?' + new URLSearchParams(queryParams).toString()
            setQueryString(result);
        }
    }, [queryParams.reportVariant])

    useEffect(() => {
        fetchData()
    }, [queryString])

    const fetchData = async () => {
        if (queryString) {
            const response = await fetch(`/api/reports/generic${queryString}`, { timeout: 60000 });
            const data = await response.json();
            if (!data.success) {
                setError("Unable to load data.")
            }
            else {
                if (data.payload?.results.length > 0) {

                    setData(data.payload.results);
                    setDefaultSortBy(data.payload.defaultSortBy);
                }
                setColumnDefs(data.payload.columnDefinitions);
                setHeaderStyles(data.payload.headerStyles);
                setReportTitle(queryParams.reportTitle);
            }

            setIsLoading(false);
        }

        //setIsLoading(false);
    }

    const getTextForSpinner = () => {
        return (
            <><b>Fetching Report</b><br />{queryParams.reportTitle}</>
        )
    }

    return (
        <>
            {
                //JSON.stringify(searchParams, null, 2)
            }
            <LoadingOverlayWrapper
                active={isLoading}
                spinner
                text={getTextForSpinner()} hideChildrenIfLoading={true}
            >

                {error ? <div className="text-danger bold">{error}</div> : null}
            {/*<Container fluid>*/}
            {/*    <Row>*/}
            {/*        <Col>*/}
            {/*            <DataTable*/}
            {/*                columns={columnDefs}*/}
            {/*                headerStyles={headerStyles}*/}
            {/*                defaultSortBy={defaultSortBy}*/}
            {/*                reportTitle={reportTitle}*/}
            {/*                data={data} />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
                {/*    </Container>*/}
                <DataTable
                    columns={columnDefs}
                    headerStyles={headerStyles}
                    defaultSortBy={defaultSortBy}
                    reportTitle={reportTitle}
                    data={data} />
            </LoadingOverlayWrapper>
            {
                //    JSON.stringify(data, null, 2)
            }
        </>
    );

}


export default ReportViewer;